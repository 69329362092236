import Vue from 'vue';
import App from './App.vue';
import router from './router'
import http from './api/http'
import store from './store'
import ViewUI from 'view-design';
import './index.less'
import './assets/less/main.less'
import './assets/css/icon.css';

//安装 npm install v-selectpage
import vSelectPage from 'v-selectpage';

import componentLib from './components/custom/customComponent.js'
Vue.use(componentLib);

Vue.use(vSelectPage,{
dataLoad: function(vue, url, params){
    return new Promise((resolve, reject)=>{
            http.getList(url,params).then(resp => resolve(resp), resp => reject(resp));
        });
    }
});

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);

Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' },
    zIndex: 99999
})

Vue.use(ViewUI, {
    transfer: true
});

Vue.prototype.$http = http

Vue.prototype.$bus = new Vue();

Vue.prototype.$config = {
    homeName: 'home'
}

Vue.config.productionTip = false

import {permissions} from './router/util'

Vue.directive('permission',{
    // 当绑定元素插入到 DOM 中
    inserted(el,binding){
        let displayKey = binding.value;
        if(displayKey){
            let hasPermission = permissions.indexOf(displayKey)>-1;
            if(!hasPermission){
                //查找el的父节点并删除父节点中匹配成功的el
                el.parentNode && el.parentNode.removeChild(el);
            }
        }else{
            throw new Error(`need key! Like v-display-key = "''displayKey"`)
        }
    }
});

import customMixins from './components/custom/customMixins.js';
Vue.mixin(customMixins);

let vue = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

export default vue