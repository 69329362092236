import {
  getHomeRoute,
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  getTagNavListFromLocalstorage,
  routeHasExist,
  routeEqual,
  getNextRoute,
  isHome
} from '@/utils'
import router from '@/router'
import http from '@/api/http'
const homeName = 'home'

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter(item => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}
export default {
  state: {
    menus:null,
    notReaded:0,
    homeRoute: {},
    breadCrumbList: [],
    tagNavList: [],
  },
  getters: {
  },
  mutations: {
    clearMenus(state){
      state.menus=null;
    },
    setNotReaded(state,value){
        state.notReaded=value;
    },
    updateMenus(state,value){
        state.menus=value;
    },
    setBreadCrumb (state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    setHomeRoute (state, routes) {
      state.homeRoute = getHomeRoute(routes, homeName)
    },
    setTagNavList (state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else tagList = getTagNavListFromLocalstorage() || []
      if (tagList[0] && !isHome(tagList[0],homeName)) tagList.shift()
      let homeTagIndex = tagList.findIndex(item => isHome(item,homeName))
      if (homeTagIndex > 0) {
        let homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
      state.tagNavList = tagList
      setTagNavListInLocalstorage([...tagList])
    },
    addTag (state, { router, type = 'unshift' }) {
      if (!router.name) return
      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (isHome(router,homeName)) state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }
        setTagNavListInLocalstorage([...state.tagNavList])
      }else {
        let tag = state.tagNavList.filter(item => routeEqual(item, router));
        if(tag && router.meta){
            tag[0].meta = router.meta;
        }
      }
    },
    closeTag (state, route) {
      let tag = state.tagNavList.filter(item => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route)
    },
  },
  actions: {
    getSysMenus (context) {
      return new Promise((resolve,reject)=>{
        http.get('/api/other/getSysMenusForMenu').then(data=>{
          context.commit('updateMenus',data);
          resolve(data);
        }).catch((err)=>{
          reject(err);
        });
      });
    }
  }
}
