import Cookies from 'js-cookie'

const prefix = 'QZB_';
const localTagCacheName = prefix + 'tagNavList';
const USER_INFO_KEY = prefix + "USER_INFO";
const TOKEN_KEY = prefix + 'jwt-token';
let userInfo = null;

function getUserInfo(){
	if(!userInfo){
     userInfo = JSON.parse(localStorage.getItem(USER_INFO_KEY));
	}
	return userInfo || {};
}
	
export const setUserInfo = (user) =>{
    Cookies.set(TOKEN_KEY, 1, { expires: 365});
    localStorage.setItem(USER_INFO_KEY,JSON.stringify(user));
    userInfo = user;
}

export const getToken = () => {
    if(Cookies.get(TOKEN_KEY)){
        return getUserInfo().token;
    }else {
        return null;
    }
}

export const clearUserInfo = ()=>{
	Cookies.set(TOKEN_KEY, '', { expires: 0});
    localStorage.removeItem(USER_INFO_KEY);
    userInfo = null;
}

export const clearCache = ()=>{
    localStorage.removeItem(localTagCacheName);
}

export const getAuthList = () =>{
	return getUserInfo().authList || [];
}

export const getRoleId = () =>{
	let userdetail = getUserInfo().userDetail;
	if(userdetail){
		return userdetail.roleid;
	}
	return null;
}

export const getUserDetail = () => {
  return getUserInfo().userDetail || {};
}

export const isHome = (item,homeName = 'home') =>{
   return item.name == homeName || (item.meta && item.meta.isIndex);
}

export const getHomeRoute = (routers, homeName = 'home') => {
  let i = -1
  let len = routers.length
  let homeRoute = {}
  while (++i < len) {
    let item = routers[i]
    if (item.children && item.children.length) {
      let res = getHomeRoute(item.children, homeName)
      if (res.name) return res
    } else {
      if (isHome(item,homeName)) homeRoute = item
    }
  }
  return homeRoute
}

export const getBreadCrumbList = (route, homeRoute) => {
  let homeItem = { ...homeRoute, icon: homeRoute.meta.icon }
  let routeMetched = route.matched
  if (routeMetched.some(item => item.name === homeRoute.name)) return [homeItem]
  let res = routeMetched.filter(item => {
    return item.meta === undefined || !item.meta.hideInBread
  }).map(item => {
    let meta = { ...item.meta }
    if (meta.title && typeof meta.title === 'function') {
    meta.__titleIsFunction__ = true
    meta.title = meta.title(route)
  }
  let obj = {
    icon: (item.meta && item.meta.icon) || '',
    name: item.name,
    meta: meta
  }
  return obj
})
  res = res.filter(item => {
    return !item.meta.hideInMenu
  })
  return [...res]
}

/**
 * @description 本地存储和获取标签导航列表
 */
export const setTagNavListInLocalstorage = list => {
  localStorage[localTagCacheName] = JSON.stringify(list);
}
/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
  const list = localStorage[localTagCacheName];
  return list ? JSON.parse(list) : [];
}

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
  let i = -1
  while (++i < times) {
    callback(i)
  }
}

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 */
export const routeHasExist = (tagNavList, routeItem) => {
  let len = tagNavList.length
  let res = false
  doCustomTimes(len, (index) => {
      if (routeEqual(tagNavList[index], routeItem)) res = true
  })
  return res
}

/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
  return (route1.name === route2.name)
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute
  let newList = [...list]
  if (newList.findIndex(item => item.name === name) >= 0) return newList
else newList.push({ name, path, meta })
  return newList
}

export const showTitle = (item) => {
  let { title} = item.meta
  if (!title) return
  return title
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {}
  if (list.length === 2) {
    res = getHomeRoute(list)
  } else {
    const index = list.findIndex(item => routeEqual(item, route))
    if (index === list.length - 1) res = list[list.length - 2]
    else res = list[index + 1]
  }
  return res
}

export const strToArr = (str,fg=',') =>{
	let arr = [];
	if(str){
		arr = str.split(fg);
	}
	return arr;
}
export const arrToStr = (arr,fg=",") =>{
	if(arr){
		return arr.join(fg);
	}
	return '';
}

export const inArray = (value,arr) =>{
   if(arr){
      for(let i in arr){
        if(value === arr[i]){
           return true;
        }
      }
   }
   return false;
}

let errMsgObj = {
  'Network Error': '网络错误'
}

export const getErrMsg = (error) =>{
   let errMsg = '';
   if(typeof error === 'object'){
       if(error.message){
          errMsg = errMsgObj[error.message];
       }
   }
   return errMsg || '请求失败';
}

export const handleRoutes = (routes,parentRoute) =>{
  if(routes){
     routes.forEach(function(route){
        if(!route.meta){
            if(parentRoute && parentRoute.redirect){
               route.meta = Object.assign({},parentRoute.meta);
               route.meta.hideInMenu = true;
            }else {
               route.meta = {icon: '',parentName: route.name};
            }
        }else if(!parentRoute){
            route.meta.parentName = route.name;
        }
        if(route.children){
            handleRoutes(route.children,route);
        }
     });
  }
  return routes;
}

export const renderShowColor = (h,{row,column}) =>{
  let color = row[column.key];
  let style = '';
  if(color){
      style = 'padding-left: 2px;border-left: 10px solid ' + color;
  }
  return h('span',{style: style},color);
}
export const renderShowImage = (h,{row,column}) =>{
  let image = row[column.key];
  return h('viewer',{props: {images: [image]}},[h('img',{class: 'custom-img-container',attrs: {src: image}})]);
}

export const renderShowTitle = (h,params)=>{
   return renderShowEllipsisTitle(h,params);
}

export const renderShowEllipsisTitle = (h,{row,column})=>{
   let key = column.key;
   let title = row[key];
   let titleColor = row[key + 'Color'];
   let data = [];
   titleColor && data.push(h('div',{domProps: {title: titleColor}},[h('span',{style: 'display:inline-block;width: 15px;height: 15px;margin-right: 3px;background:' + titleColor})]));
   title && data.push(h('Tooltip',{props: {content: title},class: 'hide-text',style: 'text-align:left'},title));
   return h('div',{style: 'display: flex;flex-direction: row;justify-content: center;'},data);
}

export const renderShowAuditStatus =(h,{row,column})=>{
    let value = row[column.key];
    if(value == 0){
        return h('Tag',{props: {color: 'default'}},'待审核');
    }else if(value == 1){
        return h('Tag',{props: {color: 'primary'}},'审核通过');
    }else {
        return h('Tag',{props: {color: 'error'}},'审核失败');
    }
}

export const renderShowUrl = (h,{row,column}) =>{
    let value = row[column.key];
    return h('div',{class: 'hide-text', domProps: {title: value}}, value)
}

export const checkFileFormat = (filePath,formats = [])=>{
    if(!filePath){
        return false;
    }
    let index= filePath.lastIndexOf(".");
    let ext = filePath.substr(index + 1);
    return formats.find((format) => { return format == ext}) != undefined;
}

export function resetSelectPage(ref,skip){
    if(ref){
        ref.remove && ref.remove();
        if(ref.pageNumber == 1 && skip === true){
            return ;
        }
        ref.pageChange && ref.pageChange(1);
    }
}

export function imageSizeFormat(size){
  let M = 1024 * 1024,K = 1024;
  if(size > M){
     let m = parseInt(size / M);
     return parseFloat(m + (size - m * M) / M).toFixed(2) + 'MB';
  }else if(size > K){
     let k = parseInt(size / K);
     return parseFloat(k + (size - k * K) / K).toFixed(2) + 'KB';
  }
  return size + 'B';
}

export function formatDate(date, fmt) {
	if(typeof date === 'number'){
		if(date == 0){
			return '';
		}
		date = new Date(date);
	}
	if(!date || date.getTime() == 0){
		return '';
	}
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}

function padLeftZero (str) {
  return ('00' + str).substr(str.length);
}

export function minutesToHours(minutes){
	if(minutes % 60 == 0){
		return parseInt(minutes / 60);
	}else {
		let value = minutes / 60.0;
		return value.toFixed(2);
	}
}