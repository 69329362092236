export const permissions=[];
export function formatMenu(list){
    let menus=[];
    list.forEach(item=>{
        let menu={
            path: item.path,
            name: item.name,
            component:  (resolve) => require([`@/${item.component}`], resolve),
            meta: {
                title: item.title,
                icon: item.icon
            }
        }
        if(item.children.length>0){
            menu.children=formatMenu(item.children);
        }
        if(item.type==1){
            menus.push(menu);
        }else{
            permissions.push(item.permission);
        }
    })
    return menus;
}