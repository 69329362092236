import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";
import routes from './router'
import {formatMenu,permissions} from './util'

Vue.use(Router)
import {getToken} from '@/utils'
const homeName = 'home'

const router = new Router({
  routes,
  mode: 'hash'
})
const LOGIN_PAGE_NAME  = 'login';

router.beforeEach((to, from, next) => {
  const token = getToken();
  if(!token) {
      if(to.name !== LOGIN_PAGE_NAME){
          next({name: LOGIN_PAGE_NAME})
      }else {
          next();
      }
  }else {
      let jump = (replace)=>{
          if(to.name == LOGIN_PAGE_NAME){
              next({name: homeName});
          }else if(replace){
              next({...to, replace: true});
          }else {
              next();
          }
      }
      let tmpMenus = store.state.app.menus;
      if(!tmpMenus){
          store.dispatch('getSysMenus').then(data=>{
             let menus=formatMenu(data);
             menus.forEach(item=>{
                router.addRoute(item);
                router.options.routes.push(item);
             });
             jump(true);
          }).catch(()=>{
             jump();
          });
      }else {
          jump();
      }
  }
})
export default router;

//解决重复点击菜单报错问题
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
Router.prototype.push = function push (location, onResolve, onReject) {
  if(onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Router.prototype.replace = function push (location, onResolve, onReject) {
  if(onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
