import Home from '@/components/common/Home'
import HelloWorld from "@/views/HelloWorld"

let routeList = [{
        path: '/login',
        name: 'login',
        meta: {
            title: 'Login - 登录',
            hideInMenu: true
        },
        component: () =>
            import ('@/views/Login.vue')
    },
    {
        path: '/',
        name: 'index',
        component: Home,
        redirect: '/home',
        meta: {
            title: '首页',
            icon: 'md-home',
            isIndex: true
        },
        children: [{
            path: '/home',
            name: 'home',
            meta: {
                title: '首页',
                icon: 'md-home'
            },
            component: HelloWorld
        }]
    }
];

export default routeList;