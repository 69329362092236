<template>
  <div class="header">
    <v-sider-trigger :collapsed="collapsed" @on-change="handleCollpasedChange"></v-sider-trigger>
    <v-bread-crumb style="margin-left: 30px;" :list="breadCrumbList"></v-bread-crumb>
    <div class="user-avator-dropdown">
      <Dropdown @on-click="handleClick">
        <Badge>
          <Avatar src="https://file.iviewui.com/dist/a0e88e83800f138b94d2414621bd9704.png"/>
        </Badge>
        <Icon :size="18" type="md-arrow-dropdown"></Icon>
        <DropdownMenu slot="list">
          <DropdownItem name="modifypassword">修改密码</DropdownItem>
          <DropdownItem name="logout">退出登录</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>

    <Modal 
        v-model="modal"
        title="修改密码">
      <Form ref="pform" :model="pform" :rules="ruleValidate" :label-width="100">
        <FormItem label="原密码" prop="oldPassword">
            <CustomPassword v-model="pform.oldPassword" placeholder="请输入原密码"></CustomPassword>
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
            <CustomPassword v-model="pform.newPassword" placeholder="请输入新密码"></CustomPassword>
        </FormItem>
        <FormItem label="确认密码" prop="passwordConfirmation">
            <CustomPassword v-model="pform.passwordConfirmation" placeholder="请输入确认密码"></CustomPassword>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" size="large" @click="modal=false">取消</Button>
        <Button type="primary" size="large" @click="handleSubmit()">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
    const moment = require('moment');
    import VSiderTrigger from './SiderTrigger'
    import VBreadCrumb from './BreadCrumb'
    import {clearUserInfo} from "@/utils"
    import router from '@/router'
    import store from '@/store'
    export default {
        data() {
            return {
                pn:1,
                ps:10,
                total:0,
                modal: false,
                messageModal:false,
                pform:{
                    oldPassword: '',
                    newPassword: '',
                    passwordConfirmation: ''
                },
                ruleValidate:{
                    oldPassword:[{ required: true, message: '原密码不能为空', trigger: 'blur' }],
                    newPassword:[{ required: true, message: '新密码不能为空', trigger: 'blur' }],
                    passwordConfirmation:[{ required: true, message: '确认密码不能为空', trigger: 'blur' }]
                },
                columns:[
                    {
                        key: 'content',
                        title: '内容',
                        align: 'left'
                    },{
                        width:150,
                        key: 'createTime',
                        title: '创建时间',
                        render: (h, params) => {
                            return h('div',moment(params.row.createTime).format('YYYY-MM-DD HH:mm:ss'));
                        }
                    },{
                        title: '操作',
                        fixed:'right',
                        width:100,
                        render: (h, params) => {
                            let data = [];
                            if(params.row.readed == 0){
                                data.push(h('Button', {
                                    props: {
                                        type: 'info',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.opReaded(params.row.id)
                                        }
                                    }
                                }, '已读'));
                            }
                            return h('div', data);
                        }
                    }
                ],
                list:[]
            }
        },

    computed: {
      breadCrumbList () {
           return this.$store.state.app.breadCrumbList
      }
    },
    mounted(){
    },
    props: {
      collapsed: Boolean
    },
    methods: {
      handleCollpasedChange (state) {
        this.$emit('on-coll-change', state)
      },
      handleSubmit(){
        this.$refs['pform'].validate((valid) => {
            if(valid) {
                this.$http.post('/api/user/modifyPassword',this.pform).then(()=>{
                  this.$Message.success("操作成功！");
                  this.modal = false;
                })
            }
        })
      },
      handleClick (name) {
        let that=this;
        switch (name) {
          case 'logout':
            clearUserInfo()
            store.commit('clearMenus');
            router.push('login')
            window.location.reload();
            break;
          case 'modifypassword':
            that.$refs.pform.resetFields();
            that.modal=true;
            break;
        }
      }
    },
    components:{VSiderTrigger,VBreadCrumb}
  }
</script>

<style lang="less" scoped>
  .header {
    width: 100%;
    height: 100%;
    position: relative;
    .custom-content-con{
      float: right;
      height: auto;
      padding-right: 20px;
      line-height: 64px;
      & > *{
        float: right;
      }
    }
  }

  .user{
    &-avator-dropdown{
      cursor: pointer;
      display: inline-block;
      position:absolute;
      top:0;
      right: 20px;
      // height: 64px;
      vertical-align: middle;
      // line-height: 64px;
      .ivu-badge-dot{
        top: 16px;
      }
    }
  }
  .institution-message{
      cursor: pointer;
      display: inline-block;
      position:absolute;
      top:0;
      right: 120px;
      vertical-align: middle;
  }
  .badge{
      width:10px;
  }
</style>
