<template>
  <div id="app">
    <v-loading></v-loading>
    <router-view></router-view>
  </div>
</template>

<script>
  import VLoading from "@/components/common/Loading";
  export default {
    name: 'app',
    created(){
    },
    methods: {
    },
    components:{VLoading}
  }
</script>