import axios from 'axios'
import { getToken, getErrMsg } from "@/utils"
import router from '@/router'
import context from '@/main.js'
import { Message } from 'view-design'

export const baseUrl = process.env.VUE_APP_BASE_URL;

const service = axios.create({
    baseURL: baseUrl, // api的base_url
    timeout: 60000 // 请求超时时间
});

export default service

// request拦截器，实现loading加载
service.interceptors.request.use(config => {
    setLoad(config, true);
    const token = getToken();
    if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    }
    config.headers.Authorization = 'Bearer ' + token;
    config.timeout = 60000;
    if (!token && config.notLogin !== true) {
        router.push({ name: "login" })
        return Promise.reject("请先登录")
    }
    return config
}, error => {
    context.$bus.$emit('loading', false);
    Message.error(getErrMsg(error))
    return Promise.reject(error)
})

// response拦截器，实现loading关闭
service.interceptors.response.use(resp => {
    setLoad(resp.config, false);
    if (resp.headers['content-type'] == 'application/octet-stream') {
        return resp;
    }
    const { code, msg, data } = resp.data;
    if (!resp.data.code) {
        return resp.data;
    }
    if (code != 200) {
        msg && Message.error({
            content: msg,
            duration: 3
        });
        return Promise.reject(msg)
    }
    return data
}, error => {
    context.$bus.$emit('loading', false);
    Message.error(getErrMsg(error))
    return Promise.reject(error)
})

function setLoad(obj, flag) {
    if (obj && obj.loading !== false) {
        context.$bus.$emit('loading', flag);
    }
}