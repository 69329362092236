<template>
  <div class="home">
      <Row :gutter="20">
        <Col :lg="6">
          <InfoCard shadow icon="md-locate" color="rgb(25, 190, 107)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.enterpriseNum | defaultIfNull}}</span></p> 
              </div>
            <p>企业数量</p>
          </InfoCard>
        </Col>
        
        <Col :lg="6">
          <InfoCard shadow icon="md-flag" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.techTalentNum | defaultIfNull}}</span></p> 
              </div>
            <p>科技人才数量</p>
          </InfoCard>
        </Col>

        <Col :lg="6">
          <InfoCard shadow icon="md-beer" color="rgb(255, 153, 0)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.srPlatformNum | defaultIfNull}}</span></p> 
              </div>
            <p>科研平台数量</p>
          </InfoCard>
        </Col>

        <Col :lg="6">
          <InfoCard shadow icon="md-desktop" color="rgb(237, 63, 20)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.siCarrierNum | defaultIfNull}}</span></p> 
              </div>
            <p>科创载体数量</p>
          </InfoCard>
        </Col>

        <Col :lg="6">
          <InfoCard shadow icon="md-flag" color="rgb(204, 102, 153)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.techConsultNum | defaultIfNull}}</span></p> 
              </div>
            <p>科技咨询数量</p>
          </InfoCard>
        </Col>

        <Col :lg="6">
          <InfoCard shadow icon="md-desktop" color="rgb(153, 102, 204)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.resultInteractionNum | defaultIfNull}}</span></p> 
              </div>
            <p>成果交互数量</p>
          </InfoCard>
        </Col>

        <Col :lg="6">
          <InfoCard shadow icon="md-flag" color="rgb(102, 255, 255)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.mienNum | defaultIfNull}}</span></p> 
              </div>
            <p>栖智风采数量</p>
          </InfoCard>
        </Col>

        <Col :lg="6">
          <InfoCard shadow icon="md-desktop" color="rgb(204, 102, 153)" :icon-size="36">
            <div class="count-to-wrapper"> 
              <p class="content-outer">
                <span class="count-style">{{stat.popularWorldNum | defaultIfNull}}</span></p> 
              </div>
            <p>科普天地数量</p>
          </InfoCard>
        </Col>
    </Row>
  </div>
</template>

<script>
import InfoCard from '@/components/common/InfoCard';

export default {
    name: 'home',
    data () {
        return {
            stat: {}
        }
    },
    created() {
        this.queryIndexStatData();
    },
    methods: {
        queryIndexStatData(){
            this.$http.post('/api/admin/queryIndexStatData').then((data)=>{
                this.stat = data || {};
            });
        }
    },
    filters: {
        defaultIfNull(value,def = 0){
            if(value == null || value == undefined){
                return def;
            }
            return value;
        }
    },
    components: {InfoCard}
}
</script>
<style scoped>
.count-style{
    font-size: 50px;
}
.ivu-col{
    height: 120px;padding-bottom: 10px;
}
</style>