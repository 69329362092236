import FETCH from './fetch'
import qs from 'qs'

export default {
  post(url,requestForm,config){
      return FETCH.post(url,qs.stringify(requestForm,{arrayFormat: 'brackets'}),getRequestConfig(config));
  },
  get(url,requestForm,config = {}){
      return FETCH.get(url + '?' + qs.stringify(requestForm,{arrayFormat: 'brackets'}),config);
  },
  download(url,requestForm){
      downloadFile(FETCH,url,qs.stringify(requestForm,{arrayFormat: 'brackets'}));
  },
  uploadFile(url,formData){
      return FETCH.post(url,formData);
  },
  getList(url,requestForm){
      return FETCH.post(url,qs.stringify(requestForm),{loading: false}); 
  }
}

function downloadFile(fetch,url, data) {
  return new Promise((resolve, reject) => {
    fetch({
       method: 'post',
       url: url,
       data: data,
       responseType: 'blob'
    }).then(response => {
      resolve(response.data)
      let blob = new Blob([response.data]);
      let fileName = response.headers['content-disposition'];
      fileName = decodeURIComponent(fileName.substring(fileName.indexOf('filename=') + 9)).replace(/\\"/g,'');
        if(window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName)
        } else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(link.href)
        }
      },
      err => {
        reject(err)
      }
    )
  })
}
function getRequestConfig(param){
  let config = {};
  if(typeof param == 'boolean'){
     config.loading = param;
  }else if(typeof param == 'object'){
     config = param;
  }
  return config;
}