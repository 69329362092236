export default {
    data(){
        return {
            routeTitle: ''
        }
    },
    created(){
        let name = this.$route && this.$route.name;
        let tag = this.$vnode && this.$vnode.tag;
        if(name && tag && tag.indexOf(name) != -1){
            this.setRouteTitle();
        }
    },
    methods: {
        setRouteTitle(){
            let $route = this.$route;
            if($route && $route.meta){
                this.routeTitle = $route.meta.title;
            }
        }
    }
}